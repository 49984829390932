function getHistoryState(history, state) {
  const { addr, shortId, campaignShortId, token, demo, iFrame, reactNativeFlow, initUrl, templateId, locationId } = history.location.state || {};

  return {
    ...(state || {}),
    addr,
    shortId,
    campaignShortId,
    token,
    demo,
    iFrame,
    reactNativeFlow,
    initUrl,
    templateId,
    locationId
  };
}

export function handleHistoryPush(history, pathname, state) {
  history.push({
    pathname,
    state: getHistoryState(history, state),
  });
}

export function handleBackToMainForNativeApp(history) {
  const { templateId, locationId, reactNativeFlow, initUrl } = getHistoryState(history)
  history.push({
    pathname: "/home",
    state: {
      templateId,
      locationId,
      iFrame: true,
      reactNativeFlow,
      initUrl,
    },
  });
}

export function handleHistoryReplace(history, pathname, state) {
  history.replace({
    pathname,
    state: getHistoryState(history, state),
  });
}
