import React, { useContext } from "react";
import { MainWrapper } from "./styles/IntroStyle";
import {
  MessageSuccess,
  Qnumber,
  SocialTab,
  FollowUS,
  GetSocialText,
  PrintWrap,
  PrintButton,
  PoweredByOld,
  MessageContainer,
  MsgHeader,
  LastPageLogoWrapper,
  LastPageLogo,
  Success,
  ShowIt,
  Qnum,
} from "./styles/successMessage";
import { Phrase } from "./Phrase";
import { useEffect } from "react";
import { ContentContext, MainContext } from "../context";
import { handleBackToMainForNativeApp, handleHistoryPush, handleHistoryReplace } from "../utils/history";
import { GaEvents, sendGAEvent } from "../utils/googleAnalytics";
import { PopUp } from "./popup/PopUp";

const Result = (props) => {
  const { content } = useContext(ContentContext);
  const { main } = useContext(MainContext);
  const { number, imageUrl, reactNativeFlow } = props.location.state || {};

  useEffect(() => {
    if (imageUrl && number && window.location !== window.parent.location) {
      const type = "rippleSelected";
      const description = "ripple queue number and imageUrl";

      const message = JSON.stringify({
        type,
        number,
        imageUrl,
        description,
      });
      window.parent.postMessage(message, "*");
    }
  }, [number, imageUrl]);

  const handleBackBtn = () => {
    if (main.preview) {
      handleHistoryReplace(props.history, "/preview");
      return;
    }
    if (reactNativeFlow) {
      return handleBackToMainForNativeApp(props.history);
    }
    handleHistoryPush(props.history, "/home");
  };

  if (!content || !number) {
    handleHistoryReplace(props.history, '/home')
    return null;
  }

  // get style
  const { edit, general, results } = content || {};
  const { buttons, texts, social, poweredBy: poweredByStyle } = results || null;
  const { followMe: followMeStyle } = social || {};
  const { instagram: followAddress } = followMeStyle || null;
  const { getSocial: getSocialStyle } = social.texts;
  const { youdidit: successStyle, showOurStaff: showIt, qNumber: qNumberStyle } = texts;
  const { printAnotherButton: printAnotherStyle } = buttons;
  const { messageBackgroundColor } = general.colors;

  const { logo } = edit || {};
  const { lastPageLogo, logo: firstPageLogo } = (general && general.images) || {};
  const logoLocation = (lastPageLogo && lastPageLogo.location) || logo.location || firstPageLogo.location || "";

  const followMe = () => {
    sendGAEvent(GaEvents.ClickOnSocial, {
      eventCategory: "social",
      eventLabel: followAddress,
      locationId: content.locationId,
    });
    if (!main.preview) {
      window.location.href = followAddress;
    }
  };

  return (
    <>
      <MainWrapper id="successmw" backgroundColor={general.colors.backgroundColor}>
        <MsgHeader id="msgHeader">
          {logoLocation && (
            <LastPageLogoWrapper>
              <LastPageLogo src={logoLocation} alt="" height={"100px"} />
            </LastPageLogoWrapper>
          )}
        </MsgHeader>
        <MessageContainer id="msgContainer">
          <MessageSuccess id="msgSuccess">
            <Success style={successStyle}>
              <Phrase Key={successStyle.translationKey} default={successStyle.text} />
            </Success>
            <ShowIt style={showIt}>
              <Phrase Key={showIt.translationKey} default={showIt.text} />
            </ShowIt>
          </MessageSuccess>
          <Qnumber id="qnumber">
            <Qnum style={qNumberStyle}>{number || "0"}</Qnum>
          </Qnumber>
          <PrintWrap id="PrintWrap">
            <PrintButton id="printButton" style={printAnotherStyle} onClick={handleBackBtn}>
              <Phrase Key={printAnotherStyle.translationKey} default={printAnotherStyle.text || ""} />
            </PrintButton>
          </PrintWrap>
          <br />
        </MessageContainer>

        <SocialTab id="socialtab" background={messageBackgroundColor}>
          <GetSocialText id="getsocialtext" style={getSocialStyle}>
            <Phrase Key={getSocialStyle.translationKey} default={getSocialStyle.text} />
          </GetSocialText>
          <FollowUS data={followMeStyle} onClick={followMe}></FollowUS>
          <PoweredByOld data={poweredByStyle}></PoweredByOld>
        </SocialTab>
      </MainWrapper>
      {!main.isUserAnswer && <PopUp />}
    </>
  );
};
export default Result;
