import React, { useEffect } from "react";
import queryString from "query-string";


const IFrame = (props) => {
 
  useEffect(() => {
    goToHomeScreenFromIFrame()
    //eslint-disable-next-line
  },[])

  // Explanation regarding reactNativeFlow:
  // From RN app we have 2 flows (reactNativeFlow): 
  // 'CreateDesign' -> The Webapp only Create the design and send it back to RN, but not sending to mobile Q.
  // 'AssignDesign' -> The Webapp doing its regular flow to send the design to Mobile Q, but also sending the image to RN.

  const goToHomeScreenFromIFrame = () => {
    const { locationId, templateId, reactNativeFlow } = queryString.parse(props.location.search);
    const initUrl = props.location.pathname + props.location.search;
  
    props.history.push({
      pathname: "/home",
      state: {
        templateId,
        locationId,
        iFrame: true,
        reactNativeFlow,
        initUrl, 
      },
    });
  };
  
  return (
    <></>
  );
};

export default IFrame;
