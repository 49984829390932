import React from "react";
import { GalleryFlow, LibraryFlow, SurpriseMeFlow } from "./index";
import { fileSelectedHandler } from "../../actions";
import { ActionButtonsWrapper } from "../styles/IntroStyle";

export function ActionButtons(props) {
  let { content, ripples, surprise, isPreview, onSelectRipple, onSurpriseClick, isOneMacaron, onCreateText, style = {}, isMacaron = false, reactNativeFlow } = props;
  const { isGalleryEnabled, isSnapEnabled, isTextModeEnabled, isLibraryEnabled, isSurepriseMeEnabled } =
    (content.general && content.general.workflows) || (content.configuration && content.configuration.features) || {};
  const isLibraryButtonEnabled = isLibraryEnabled && (!!(ripples && ripples.length) || isPreview);
  // todo(vmyshko): fix typo: surEprise -> surprise
  const isSurpriseMeEnabled = isSurepriseMeEnabled && (!!(surprise && surprise.length) || isPreview);

  return (
    <ActionButtonsWrapper id="action" style={{ ...style, flexDirection: 'column' }}>
      {(isGalleryEnabled || isSnapEnabled || isTextModeEnabled) && (
        <GalleryFlow data={content} onChange={(e) => fileSelectedHandler(e, props)} isPhoto={true} history={props.history} onCreateText={() => onCreateText(!isGalleryEnabled && !isSnapEnabled && isTextModeEnabled ? 'texts' : undefined)} isMacaronFlow={isMacaron || isOneMacaron} />
      )}
      {isLibraryButtonEnabled && <LibraryFlow data={content} isPreview={isPreview} history={props.history} iFrame={!!props.iFrame} reactNativeFlow={reactNativeFlow} onSelectRipple={onSelectRipple} isOneMacaron={isOneMacaron} isMacaronFlow={isMacaron || isOneMacaron} />}
      {isSurpriseMeEnabled && <SurpriseMeFlow data={content} isPreview={isPreview} history={props.history} iFrame={!!props.iFrame} reactNativeFlow={reactNativeFlow} onSurpriseClick={onSurpriseClick} isOneMacaron={isOneMacaron} isMacaronFlow={isMacaron || isOneMacaron} />}
    </ActionButtonsWrapper>
  );
}
