import React, { useEffect, useContext, useState, useRef } from "react";
import uuid from "uuid/v4";

import { PrintedTitle, MainWrapper } from "./styles/IntroStyle";
import {
  EditHeader,
  PrintWrap,
  EditContainer,
  PrintButton,
  RemoveImg,
  TitleWrapper,
  NewTitle,
  CloseBtn,
  EditOptionsContainer,
  EditButton,
  EditButtonWrapper,
} from "./styles/editStyle";
import { BackBtn, QuestionBtn } from "./styles/commonStyle";
import { STextRoboto } from "./styles/textStyle";
import { Loader } from "./styles/loader";
import { TabActions } from "./TabActions";
import { Phrase } from "./Phrase";
import { ContentContext, MainContext, FramesContext, ActiveFrameContext } from "../context";
import { handleHistoryPush, handleHistoryReplace } from "../utils/history";
import { BadLocationMsg, BadConnectionMsg } from "./messages";
import { EditMode } from "./edit/EditMode";
import { DrawingArea } from "./edit/DrawingArea";
import { sendEvent, uploadModule } from "../actions";
import { GaEvents, sendGAEvent } from "../utils/googleAnalytics";
import { getPreviewImages, getPreviewImage, getImageNewSize, sketchFilterRegular, changeBrightnessAndContrastRegular, changeObjectPosition } from "./edit/ImageMode";
import HelpSection from "./edit/HelpSection";
import { blobToBase64 } from "./common/imageBlobHelper";
import { createOneMacaronRipple } from "./MacaronSelection/utils/createMacaronRipple";
import Walkthrough from "./edit/Walkthrough/WalkThrough";
import { getCookie, setCookie } from "../utils/Utils";
import { getFilters } from "../actions/getFilters";
import FullScreenLoader from "./FullScreenLoader";

export const errors = {
  location: 1,
  connection: 2,
};

export default function EditImage(props) {
  const [image, setImage] = useState(null);
  const [imageDimensions] = useState(null);
  const [error, setError] = useState("");
  const [frameUrl, setFrameUrl] = useState("");
  const [printEnable, setPrintEnable] = useState(true);
  const [editMode, setEditMode] = useState("edit");
  const [editTextMode, setEditTextMode] = useState(false);
  const [cText, setText] = useState("");
  const [filter, setFilter] = useState();
  const [cTextFont, setTextFont] = useState("NesspressoLucas");
  const [textClicks, setTextClicks] = useState(0);
  const [textClicksDemo, setTextClicksDemo] = useState(0);
  const [fontColor, setFontColor] = useState("black");
  const { content } = useContext(ContentContext);
  const { main, setMain } = useContext(MainContext);
  const { frames } = useContext(FramesContext);
  // todo(vmyshko): why all those states are here,
  // not inside <EditMode/> component which uses them solely?
  const [activeFont, setActiveFont] = useState(0);
  const [imageScroll, setImageScroll] = useState(0);
  const [framesScroll, setFramesScroll] = useState(0);
  const [fontScroll, setFontsScroll] = useState(0);
  const [imageChange, setImageChange] = useState(false);
  //

  const [imageStatus, setImageStatus] = useState("");
  const [frameImageStatus, setFrameImageStatus] = useState("");

  const editContainerRef = useRef();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [isImageFromLib, setIsImageFromLib] = useState(false);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const [images, setImages] = useState([]);

  const [dImageWidth, setDImageWidth] = useState(100);
  const [dImageHeight, setDImageHeight] = useState(100);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isHelpCardOpen, setIsHelpCardOpen] = useState(false);
  const [possibleCombinations, setPossibleCombinations] = useState([]);
  const [isImageFromLibEditable, setIsImageFromLibEditable] = useState(false);
  const [imageDrawChange, setImageDrawChange] = useState(false);
  const [previewInit, setPreviewInit] = useState(false);
  const [customContrast, setCustomContrast] = useState({});
  const [contrastTool, setContrastToolEnable] = useState(false);
  const [prevContrast, setPrevContrast] = useState({});
  const [iFrame, setIFrame] = useState(false);
  const [uploadFlow, setUploadFlow] = useState(true);
  const [rippleId, setRippleId] = useState(undefined);
  const [isWalkThroughOpen, setIsWalkThroughOpen] = useState(false);
  const [filters, setFilters] = useState([]);

  const editTabRef = useRef();
  const textTabRef = useRef();
  const framesTabRef = useRef();

  const { setActiveFrame } = useContext(ActiveFrameContext);

  const locationStateLoaded = props.location && props.location.state;
  const locationState = props.location.state;

  let sessionId = uuid();
  const reactNativeFlow = locationState.reactNativeFlow;
  const isSubmitButtonDisabled = contrastTool || !locationStateLoaded || !imageLoaded || (locationStateLoaded && !cText && !image && !frameUrl) || (!frameUrl && !frameLoaded);
  const imageRef = useRef();
  const originalLayerRef = useRef();
  const filterSize = 80;
  let offset = (imagePosition && changeObjectPosition(imagePosition, filterSize, dImageWidth, dImageHeight)) || {};
  let vWidth, previewLayer;

  function setFrame(frame) {
    const frameUrl = frame.url ? frame.url.replace("/t_original", "") : frame.customFrameType ? frame.customFrameType : null;
    setFrameUrl(frameUrl);
    setRippleId(frame.id);
  }

  function onImageChange() {
    setImageChange((prevState) => !prevState);
  }

  function openCloseHelp() {
    const newState = !isHelpOpen;
    if (newState) {
      setIsHelpCardOpen(newState);
      setIsHelpOpen(newState);
    } else {
      setIsHelpCardOpen(newState);
      setTimeout(() => {
        setIsHelpOpen(newState);
      }, 1100);
    }
  }

  const getFilterByName = (name) => ({ ...filters.filter((e, i) => e.name === name)[0] });
  function changeFilter(name) {
    setFilter(name);
    const { filterObj } = updateImagesContrast(false, name);
    onFilterChange(filterObj);
  }

  function onFilterChange(changeFilter, previewImages) {
    previewImages = previewImages || images;

    const sketchCanvas = document.getElementById("sketchCanvas");
    let { regular } = changeFilter || filters[0];

    if (!regular) {
      sketchCanvas.width = 1000;
      sketchCanvas.height = 1000;
      sketchFilterRegular(sketchCanvas, changeFilter, 1000, 1000);
    } else {
      sketchCanvas.width = 1700;
      sketchCanvas.height = 1700;
      changeBrightnessAndContrastRegular(sketchCanvas, changeFilter, 1700, 1700);
    }
    setImageDrawChange((prev) => !prev);
  }

  useEffect(() => {
    if (frames && frames.length > 0) setActiveFrame(frames[0]);
  }, [])

  useEffect(() => {
    if (!imageRef.current) return;
    let width = Math.round(imageRef.current.width());
    let height = Math.round(imageRef.current.height());
    let { newWidth, newHeight } = getImageNewSize(vWidth, width, height, filterSize);
    setDImageWidth(newWidth);
    setDImageHeight(newHeight);
  }, [imageRef.current]);

  useEffect(() => {
    setImageLoaded(!image || imageStatus === "loaded");
  }, [image, imageStatus]);

  useEffect(() => {
    setFrameLoaded(!frameUrl || frameImageStatus === "loaded");
  }, [frameUrl, frameImageStatus]);

  function setcTextFont(fontName) {
    setTextFont(fontName);
  }

  let filtersFromContent = [];
  if (main.preview) {
    filtersFromContent = (content.configuration && content.configuration.filters) || [];
  } else {
    filtersFromContent = (content && content.general && content.general.filters) || [];
  }

  useEffect(() => {

    if (filtersFromContent.length > 0) {
      getFiltersByIds(filtersFromContent.filter(({ active }) => active).map(({ _id }) => _id))
    }
  }, [filtersFromContent])

  const getFiltersByIds = async (filtersIds) => {
    const filtersRes = await getFilters(filtersIds);
    setFilters(filtersRes);
    setFilter(filtersRes[0].name);
  }


  useEffect(() => {
    const { general, configuration } = content || {};
    const { target = {}, tabActions, fromLibrary, editable, iFrame, isUploadFlow, rippleId } = locationState;
    const { defaultEditMode, isFramesEnabled } = (general && general.workflows && general.workflows) || configuration || {};
    const { orgImage = "", screen } = target;

    setIFrame(iFrame);
    setUploadFlow(!!isUploadFlow);

    if (main.preview && previewInit) return;
    setPreviewInit(true);
    if (screen === "texts") {
      setEditMode("texts");
      return;
    }

    setNewImage(orgImage);

    if (rippleId) {
      setRippleId(rippleId);
    }

    if (fromLibrary) {
      setIsImageFromLib(fromLibrary);
      setIsImageFromLibEditable(editable);
    }

    if (editable) {
      setEditMode("texts");
    } else if (tabActions && tabActions.length) {
      setEditMode(tabActions[0]);
    }

    if (defaultEditMode && !fromLibrary) {
      if (!(defaultEditMode === "frames" && (!isFramesEnabled || !frames || !frames.length))) {
        setEditMode(defaultEditMode);
      }
    }
  }, [locationState, content, main, filters]);

  useEffect(() => {
    if (locationState.fromLibrary) return;
    if (main.preview) {
      setIsWalkThroughOpen(true);
      return;
    }

    if (locationState.iFrame) {
      window.addEventListener('message', handleMeesageRecieved)

      const message = JSON.stringify({
        key: "isWalkthroughDisplayed",
        type: "get_local_storage",
      });

      window.parent.postMessage(message, "*");

      return () => window.removeEventListener('message', handleMeesageRecieved)
    }

    const walkthroughCookie = getCookie('isWalkthroughDisplayed');
    if (!walkthroughCookie.didDisplay) setIsWalkThroughOpen(true)
  }, [])

  const handleMeesageRecieved = (event) => {
    if (event.data && event.data.shouldDisplay) setIsWalkThroughOpen(true)
  }

  function setNewImage(newImage) {
    setImage(newImage);
    getPreviewImages({ filters }).then((previewImages) => {
      setImages(previewImages);
      if (filters[0]) onFilterChange(filters[0]);
    });
  }

  function getNewPreviewImage(filterObj) {
    const { _id } = filterObj;
    let newImages = [...images];
    const newImage = getPreviewImage(filterObj);
    newImages = newImages.map(({ id: imageId, src }) => {
      if (_id === imageId) return { id: imageId, src: newImage }

      return { id: imageId, src }
    })
    setImages(newImages);
    return newImages;
  }

  function updateImagesContrast(updateImage, filterName) {
    filterName = filterName || filter;
    const filterObj = getFilterByName(filterName);
    const value = customContrast[filterName];
    let newImages;
    if (Number.isInteger(value)) {
      filterObj["m_contrast"] = value;
      filterObj["contrast"] = value;
      filterObj["contrastCSS"] = 100 + value;
    }
    if (updateImage) {
      newImages = getNewPreviewImage(filterObj);
    }

    return { filterObj, newImages };
  }

  useEffect(() => {
    if (!Number.isInteger(customContrast[filter])) return;
    const { filterObj, newImages } = updateImagesContrast(true, filter);
    onFilterChange(filterObj, newImages);
  }, [customContrast]);

  if (!content) {
    handleHistoryReplace(props.history, '/home')
    return null;
  }

  function getDefaultContrast() {
    if (Number.isInteger(customContrast[filter])) return;

    const defaultContrast = filters.filter((e) => e.name === filter)[0].m_contrast;
    const newCustom = { ...customContrast };
    newCustom[filter] = defaultContrast;
    setCustomContrast(newCustom);
    return defaultContrast;
  }

  function getPrevContrast() {
    const newPrevContrasts = { ...prevContrast };
    newPrevContrasts[filter] = customContrast[filter] || getDefaultContrast();
    setPrevContrast(newPrevContrasts);
  }
  function setContrastTool(state) {
    if (state) {
      getPrevContrast();
    }
    setContrastToolEnable(state);
  }

  function onContrastEditCancel() {
    const newCustom = { ...customContrast };
    newCustom[filter] = prevContrast[filter];
    setCustomContrast(newCustom);
    setContrastTool(false);
  }

  function onContrastEditDone() {
    setCustomContrast({ ...customContrast });
    setContrastTool(false);
  }

  async function onContrastChange(filterName, value) {
    const newCustomContrast = { ...customContrast };
    newCustomContrast[filterName] = value;
    setCustomContrast(newCustomContrast);
  }

  /*** redirect to home if there is any problem*/
  function goToHome() {
    if (locationState.macaronId) {
      const { macarons = {}, macaronAmount, iframe } = locationState;
      handleHistoryPush(props.history, "/macaron", {
        ...locationState,
        macarons,
        macaronAmount,
        iframe
      });
      return;
    }
    if (main.preview) {
      handleHistoryReplace(props.history, "/preview");
      return;
    }

    setMain({ ...main, mode: null });
    handleHistoryPush(props.history, "/home");
  }

  function goToLib() {
    if (main.preview) {
      handleHistoryReplace(props.history, "/library");
      return;
    }

    handleHistoryPush(props.history, "/library");
  }

  function setPreviewImageLoad(load) {
    setMain({ ...main, load });
  }

  /**********************************************/
  const tokenHeader = `android_1.17_fAxTeOoIu1HoZRmrBgCKzCSBamFls7Z4mp3ioUIyEEe8o9CTDweURADrWZ3N`;

  //extract content
  const general = content.general;
  const instructions = content.edit.texts.instructions;
  const textInstructions = instructions;

  const editImageStyle = instructions.EditYourPhoto;
  const movePhotoStyle = instructions.moveAndZoom;
  const newDesign = instructions.newDesign || null;
  const newDesignMacaron = instructions.newDesignMacaron || {};
  const doneText = instructions.done || {}
  const addImage = instructions.addImage || {};
  const openCamera = instructions.openCamera || {};
  const { confirm, beautifulDesigns } = instructions;
  const { filters: filterButtons, remove: removeButtons, printButton: printButtonStyle } = content.edit.buttons || {};


  // colors
  const buttonBackground = content.home.buttons.snap ? content.home.buttons.snap.backgroundColor : content.home.buttons.library.backgroundColor;
  const backgroundColor = general.colors.backgroundColor;
  const buttonTextColor = content.home.buttons.snap ? content.home.buttons.snap.textColor : content.home.buttons.library.textColor;
  const loaderColor = buttonTextColor;

  const newColors = { ...general.colors, buttonBackground };

  function cropImage() {
    return new Promise((resolve, reject) => {
      previewLayer.clipFunc((ctx) => {
        const x = window.innerWidth / 2;
        const y = vWidth / 2;
        const radius = vWidth / 2 - 1;

        ctx.arc(x, y, radius, 0, Math.PI * 2);
      });

      const ratio = 1700 / vWidth;
      previewLayer.toImage({
        x: (window.innerWidth - vWidth) / 2,
        y: 0,
        width: vWidth,
        height: vWidth,
        pixelRatio: ratio,
        quality: 1,
        callback: (data) => {
          let base64 = atob(data.src.split(",")[1]);

          if (!base64) {
            resolve(null);
          }
          let charArray = [];
          for (let i = 0; i < base64.length; i++) {
            charArray.push(base64.charCodeAt(i));
          }
          const buff = new Blob([new Uint8Array(charArray)], {
            type: "image/png",
          });

          resolve(buff);
        },
      });
    });
  }

  async function printAction() {
    if (!printEnable || isSubmitButtonDisabled) {
      return;
    }

    let createLabel = '';
    if (image && cText) {
      createLabel = 'photo&text'
    } else if (image) {
      createLabel = 'photo'
    } else if (cText) {
      createLabel = 'text'
    } else {
      createLabel = 'empty'
    }

    sendGAEvent(`${locationState.macaronId || locationState.isOneMacaron ? GaEvents.MacaronCreateMyOwn : GaEvents.DrinkCreateMyOwn}`, {
      eventCategory: "create",
      eventLabel: createLabel,
      locationId: content.locationId,
    });

    if (locationState.macaronId) {
      const { macarons = {}, macaronId, macaronAmount, iframe } = locationState;

      let croppedImg = await cropImage();
      handleHistoryPush(props.history, "/macaron", {
        ...locationState,
        macarons: { ...macarons, [macaronId.toString()]: { image: URL.createObjectURL(croppedImg) } },
        macaronAmount,
        iframe
      });

      return;
    }

    setPrintEnable(false);

    if (frameUrl) {
      sendGAEvent(GaEvents.SelectFrame, {
        eventCategory: "printing",
        eventLabel: frameUrl,
        locationId: content.locationId,
      });
    }
    if (filter) {
      sendGAEvent(GaEvents.SelectFilter, {
        eventCategory: "printing",
        eventLabel: filter,
        locationId: content.locationId,
      });
    }
    if (cText) {
      sendGAEvent(GaEvents.SelectTextStyle, {
        eventCategory: "printing",
        eventLabel: cTextFont,
        locationId: content.locationId,
      });
    }

    if (main.preview) {
      handleHistoryReplace(props.history, "/result", {
        number: 25,
        mobileQData: "",
      });
      return;
    }

    let croppedImg = await cropImage();
    if (locationState.isOneMacaron) {
      croppedImg = await createOneMacaronRipple(URL.createObjectURL(croppedImg))
    }
    if (reactNativeFlow === 'CreateDesign') { // For CreateDesign we send the image back to RN app, for AssignDesign look at the bottom
      const imageBase64 = await blobToBase64(croppedImg);
      window.ReactNativeWebView.postMessage(JSON.stringify({
        imageBase64,
        type: 'IMAGE_BLOB',
        isMacaroon: !!locationState.macaronId || !!locationState.isOneMacaron
      }));
      return; // For this flow we not continue to send it to mobileQ.
    }
    if (iFrame && !reactNativeFlow) {
      const imageBase64 = await blobToBase64(croppedImg);
      const message = JSON.stringify({
        imageBase64,
        type: "image",
        isMacaroon: !!locationState.macaronId || !!locationState.isOneMacaron
      });
      window.parent.postMessage(message, "*");
      return;
    }
    if (!croppedImg) {
      return;
    }

    const mobileQData =
      await uploadModule({ locationState, content, tokenHeader, croppedImg, rippleId, isMacaroon: locationState.isOneMacaron }).catch((err) => {
        setPrintEnable(true);
        setError(err.errorCode || errors.connection);
      })

    if (!mobileQData) return;

    // window.open(mobileQData.rippleUri)
    sendEvent({
      eventName: "PrintButton",
      location: content.locationId,
      sessionId,
      mode: main.mode,
      design: isImageFromLib ? image : null,
      filter,
      // todo(vmyshko): rename to frameUrl
      frame: frameUrl,
      text: cText,
      font: cTextFont,
      mobileq: mobileQData,
      status: "done",
    });

    if (locationState.demo) {
      const imageUrl = mobileQData.match("^http://") ? mobileQData.replace("http://", "https://") : mobileQData;
      const image = new Image();
      image.src = imageUrl;

      image.onload = () => {
        setPrintEnable(true);
        handleHistoryPush(props.history, "/result-demo", {
          number: mobileQData.ordinal || 1,
          mobileQData: imageUrl,
        });
      };
    } else {
      setPrintEnable(true);
      handleHistoryPush(props.history, "/result", {
        number: mobileQData.ordinal || 1,
        imageUrl: mobileQData.rippleUri,
        fromLibrary: locationState.fromLibrary,
      });
      // For AssignDesign we send the uploaded image url back to RN app
      if (reactNativeFlow === 'AssignDesign' || locationState.isCreateByMyself) {
        const messageObject = {
          code: "IMAGE_UPLOADED",
          message: "Image Uploaded",
          payload: {
            ...mobileQData,
            imageUrl: mobileQData.rippleUri,
            source: "user"
          }
        }
        const ReactNativeMessage = { 
          type: "IMAGE_UPLOADED",
          payload: {
            ...mobileQData,
          }

        }
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(messageObject));
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(ReactNativeMessage));
      }
    }
  }

  function updatePreviewLayer(layer, width) {
    previewLayer = layer;
    vWidth = width;
  }

  function removeImage() {
    setPrevContrast({});
    setCustomContrast({});
    setContrastToolEnable(false);
    setFilter(filters[0].name);
    setImage("");
    setPreviewInit(false);
    setUploadFlow(false);
  }

  const onWalkthroughClose = () => {
    if (locationState.iFrame) {
      const message = JSON.stringify({
        type: "set_local_storage",
        key: "isWalkthroughDisplayed",
        value: "false",
        ttl: 30
      });

      window.parent.postMessage(message, "*");
      setIsWalkThroughOpen(false)
      return;
    }

    setCookie('isWalkthroughDisplayed', { didDisplay: true }, 30);
    setIsWalkThroughOpen(false)
  }

  const tabActionsRefs = {
    'edit': editTabRef,
    'texts': textTabRef,
    'frames': framesTabRef,
  }

  const propsTabActions = locationState && locationState.tabActions;
  const disableActions = propsTabActions && propsTabActions.length === 0 && isImageFromLib;

  const doesRefsExist = editTabRef.current || textTabRef.current || textTabRef.current

  if (filters.length === 0) {
    return <FullScreenLoader />;
  }

  const RenderPrintButton = ({
    id,
    data,
    onClick,
    disabled,
    style,
    printEnable,
    macaronId,
    doneText,
    loaderColor,
    defaultKey
  }) => (
    <PrintButton
      id={id}
      data={data}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {printEnable ? (
        macaronId ? (
          <Phrase Key={doneText.translationKey} default={doneText.text || ""} />
        ) : (
          <Phrase Key={defaultKey.key} default={defaultKey.defaultText} />
        )
      ) : (
        <Loader color={loaderColor} />
      )}
    </PrintButton>
  );

  const RenderEditButtons = ({ data, onCancel, onDone }) => (
    <EditButtonWrapper>
      <EditButton data={data} onClick={onCancel}>
        Cancel
      </EditButton>
      <EditButton data={data} onClick={onDone}>
        Done
      </EditButton>
    </EditButtonWrapper>
  );

  const PrintWrapComponent = ({
    contrastTool,
    iFrame,
    reactNativeFlow,
    printButtonStyle,
    printAction,
    isSubmitButtonDisabled,
    printEnable,
    locationState,
    doneText,
    loaderColor,
    onContrastEditCancel,
    onContrastEditDone
  }) => (
    <PrintWrap id="PrintWrap">
      {contrastTool ? (
        <RenderEditButtons
          data={printButtonStyle}
          onCancel={onContrastEditCancel}
          onDone={onContrastEditDone}
        />
      ) : (
        iFrame || reactNativeFlow === 'CreateDesign' ? (
          <RenderPrintButton
            id="printButton"
            data={printButtonStyle}
            onClick={printAction}
            disabled={isSubmitButtonDisabled}
            style={{ width: '67.5%' }}
            printEnable={printEnable}
            macaronId={locationState.macaronId}
            doneText={doneText}
            loaderColor={loaderColor}
            defaultKey={{
              key: reactNativeFlow === 'CreateDesign'
                ? "d5ffef01-f5d8-45ac-8e87-200b185703ee" 
                : "c3d83da1-ba25-42cb-81d1-4701d62cd40d",
              defaultText: reactNativeFlow === 'CreateDesign' ? "Add To Channel" : "CREATE"
            }}
          />
        ) : (
          <RenderPrintButton
            id="printButton"
            data={printButtonStyle}
            onClick={printAction}
            disabled={isSubmitButtonDisabled}
            style={{ width: '67.5%' }}
            printEnable={printEnable}
            macaronId={locationState.macaronId}
            doneText={doneText}
            loaderColor={loaderColor}
            defaultKey={{
              key: printButtonStyle
                ? printButtonStyle.translationKey
                : "38a49a49-6a98-4dad-bd1f-e678aa904ff0",
              defaultText: printButtonStyle ? printButtonStyle.text : ""
            }}
          />
        )
      )}
    </PrintWrap>
  );


  return (
    <MainWrapper id="editMainWrapper" backgroundColor={backgroundColor}>
      {isWalkThroughOpen && doesRefsExist && <Walkthrough onClose={onWalkthroughClose} tabActionsRefs={tabActionsRefs} />}
      <EditHeader id="editHeader" disableActions={disableActions}>
        {(!locationState.iFrame && !reactNativeFlow) &&
          (locationState.fromLibrary ? (
            <TitleWrapper>
              <BackBtn onClick={() => printEnable && goToLib()} colors={general.colors} />
              <NewTitle colors={general.colors}>
                <STextRoboto {...newDesign}>
                  {disableActions ? (
                    <Phrase Key={confirm ? confirm.translationKey : "e8768ee4-dfaf-4126-8a40-e050fa09322a"} default={confirm && confirm.text ? confirm.text : ""} />
                  ) : (
                    <Phrase Key="98481cb5-34d3-4c91-9d4e-25c7ef6957bb" default={"Edit Design"} />
                  )}
                </STextRoboto>
              </NewTitle>
              <div style={{ width: "10%" }} />
            </TitleWrapper>
          ) : (
            <TitleWrapper>
              <QuestionBtn onClick={openCloseHelp} colors={general.colors} />
              <NewTitle colors={general.colors}>
                <STextRoboto {...newDesign}>
                  {(locationState.macaronId || locationState.isOneMacaron) ?
                    <Phrase Key={newDesignMacaron.translationKey} default={newDesignMacaron.text || ""} /> :
                    <Phrase Key={newDesign.translationKey ? "957ebf57-9845-40b8-be70-5080db2791e6" : null} default={newDesign ? newDesign.text : ""} />
                  }
                </STextRoboto>
              </NewTitle>
              <CloseBtn onClick={() => printEnable && goToHome()} color={general.colors.generalTextColor} />
            </TitleWrapper>
          ))}

        {!disableActions && (
          <TabActions
            actions={propsTabActions}
            colors={newColors}
            setEditMode={setEditMode}
            editMode={editMode}
            setFrame={setFrame}
            fromLibrary={locationState.fromLibrary}
            printEnable={printEnable}
            onContrastEditCancel={onContrastEditCancel}
            tabActionsRefs={tabActionsRefs}
          />
        )}
      </EditHeader>

      <EditContainer id="editContainer" ref={editContainerRef} vWidth={vWidth} colors={general.colors}>
        <DrawingArea
          setFrameImageStatus={setFrameImageStatus}
          applyFrame={propsTabActions}
          fromLibrary={locationState.fromLibrary}
          editable={locationState.editable !== false}
          image={image}
          setImage={setImage}
          imageRef={imageRef}
          imageDimensions={imageDimensions}
          frameUrl={disableActions ? "" : frameUrl}
          updatePreviewLayer={updatePreviewLayer}
          printStatus={printEnable}
          cText={cText}
          setText={setText}
          cTextFont={cTextFont}
          textClicks={textClicks}
          possibleCombinations={possibleCombinations}
          setPossibleCombinations={setPossibleCombinations}
          editMode={editMode}
          setEditMode={setEditMode}
          fontColor={fontColor}
          filter={filter}
          imageDrawChange={imageDrawChange}
          editTextMode={editTextMode}
          setEditTextMode={setEditTextMode}
          setImageStatus={setImageStatus}
          originalLayerRef={originalLayerRef}
          imagePosition={imagePosition}
          setImagePosition={setImagePosition}
          setError={setError}
          colors={general.colors}
          printEnable={printEnable}
          printButtonStyle={printButtonStyle}
          textClicksDemo={textClicksDemo}
          onImageChange={onImageChange}
        />
        {image && editMode === "edit" && !disableActions ? (
          <RemoveImg colors={{ ...general.colors, ...removeButtons }} disabled={!printEnable} onClick={() => printEnable && removeImage()} />
        ) : null}
      </EditContainer>
      <EditOptionsContainer>
        {!disableActions && (
          <EditMode
            data={{
              image,
              setImage,
              uploadFlow,
              setPreviewImageLoad,
              imageRef,
              images,
              general,
              editMode,
              editImageStyle,
              addImage,
              movePhotoStyle,
              filterButtons,
              filter,
              setFrame,
              printStatus: printEnable,
              cTextFont,
              cText,
              setcTextFont,
              textClicks,
              setTextClicks,
              textClicksDemo,
              setTextClicksDemo,
              possibleCombinations,
              setPossibleCombinations,
              setTextFont,
              activeFont,
              setActiveFont,
              fontScroll,
              setFontsScroll,
              framesScroll,
              setFramesScroll,
              imageScroll,
              setImageScroll,
              originalLayerRef,
              imagePosition,
              setNewImage,
              dImageWidth,
              dImageHeight,
              filterSize,
              offset,
              changeFilter,
              setFontColor,
              fontColor,
              newColors: newColors,
              fromLibrary: locationState.fromLibrary,
              imageChange,
              textInstructions,
              onContrastChange,
              customContrast,
              filters,
              contrastTool,
              setContrastTool,
              workflows: general.workflows,
              home: content.home,
              openCameraText: openCamera
            }}
          />
        )}
        {isImageFromLib && !isImageFromLibEditable && (
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <PrintedTitle data={{ color: general.colors.generalTextColor || "#000", FontSize: "20px" }}>
              <Phrase
                Key={beautifulDesigns ? beautifulDesigns.translationKey : "8225ba64-7235-4293-8389-bad3351cb249"}
                default={beautifulDesigns && beautifulDesigns.text ? beautifulDesigns.text : ""}
              />
            </PrintedTitle>
          </div>
        )}
      </EditOptionsContainer>
      <PrintWrapComponent contrastTool={contrastTool} doneText={doneText} iFrame={iFrame}
        reactNativeFlow={reactNativeFlow} isSubmitButtonDisabled={isSubmitButtonDisabled}
        loaderColor={loaderColor} locationState={locationState} onContrastEditCancel={onContrastEditCancel}
        onContrastEditDone={onContrastEditDone} printAction={printAction} printButtonStyle={printButtonStyle}
        printEnable={printEnable} />
      {error === errors.location && <BadLocationMsg data={content.messages.texts.badLocation} back={general.colors} />}
      {error === errors.connection && <BadConnectionMsg data={content.messages.texts.connectionError} setError={setError} back={general.colors} />}
      {isHelpOpen && <HelpSection actions={{ openCloseHelp }} data={{ isHelpCardOpen }} />}
    </MainWrapper>
  );
}
